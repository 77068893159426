.resizable-text-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    
  }
  
  .resizable-text-field-label {
    margin-bottom: 8px;
    color: white;
  }
  
  .resizable-text-field-input {
    resize: both;
    overflow: auto;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    min-height: 100px; /* Set a minimum height */
    border: 3px solid #1A75BB;
    border-radius: 10px;
  }
  