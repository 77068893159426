body {
    background-color: #3f3d45;
}



* {
    /* -webkit-appearance: none; */
    -moz-appearance: none;
}

* button:hover:not(:disabled) {
    background-color: #555;
}
