/* notesForm.css */

.notes-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
}

.resizable-text-field {
    margin-bottom: 20px;
}

.resizable-text-field-label {
    display: block;
    margin-bottom: 5px;
}

.resizable-text-field-input {
    width: 100%;
    min-height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical;
}

.submit-button {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.submit-button:hover {
    background-color: #0056b3;
}

.log {
    margin-top: 20px;
    color: white;
}

.log h3 {
    margin-bottom: 10px;
}

.log ul {
    list-style-type: none;
    padding: 0;
}

.log li {
    margin-bottom: 5px;
    font-size: 0.9em; /* Make the text slightly smaller */
}

.log li strong {
    font-weight: bold; /* Make the date-time bold */
}

.log li span.email {
    color: rgb(0, 204, 255); /* Make the email address blue */
}
